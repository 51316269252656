<template>
  <div class="container-fluid relative">
    <div class="row">
      <div class="loadingOverlay" v-if="deleting">
        <b-spinner small></b-spinner>
      </div>
      <div class="col file">
        <div class="icon" :class="iconColor(file.ext)">
          <svg
            v-if="
              file.ext !== 'jpg' && file.ext !== 'jpeg' && file.ext !== 'png'
            "
            viewBox="0 0 100 100"
            v-html="fileIcon(file.ext)"
          ></svg>
          <img :src="BASE_URL + file.path" v-else alt="Image" />
        </div>
        <div class="information">
          <div class="left-side">
            <b class="file-name">{{ file.name | truncate(26) }}</b>
            <p class="extension">{{ file.ext }}</p>
          </div>
          <a
            :href="BASE_URL + file.path"
            class="right-side noedit"
            target="_blank"
            v-if="!edit"
          >
            <i
              class="fal"
              :class="{
                'fa-eye':
                  file.ext === 'jpg' ||
                  file.ext === 'jpeg' ||
                  file.ext === 'png',
                'fa-download':
                  file.ext === 'pdf' ||
                  file.ext === 'docx' ||
                  file.ext === 'xlsx',
              }"
            ></i>
          </a>
          <div class="right-side cursor-pointer" v-else>
            <i class="fal fa-trash" @click="deleteFile(file.id)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../_plugins/axios";
import i18n from "../_plugins/i18n";

export default {
  data() {
    return {
      deleting: false,
    };
  },
  name: "File",
  props: {
    file: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteFile(id) {
      this.deleting = true;
      API_URL.delete(`equipment-file/${id}`).then(() => {
        this.$emit("fileRemoved", id);
        this.$toast.success(i18n.tc("notifications.success"));
        this.deleting = false;
      });
    },
    iconColor(ext) {
      switch (ext) {
        case "pdf":
          return "pdf-color";
        case "xlsx":
          return "xlsx-color";
        case "docx":
          return "docx-color";
        default:
          return "img-color";
      }
    },
    fileIcon(ext) {
      switch (ext) {
        case "pdf":
          return `<path
              d="M46.2 21.8c-3.5 0-6.3 2.9-6.3 6.3 0 4.3 2.4 9.6 4.9 14.7-2 6.1-4.1 12.7-7 18.2-5.8 2.3-11 4-14 6.6l-.2.2c-1.1 1.2-1.8 2.7-1.8 4.4 0 3.5 2.9 6.3 6.3 6.3 1.7 0 3.4-.6 4.4-1.8 0 0 .2 0 .2-.2 2.3-2.7 5-7.8 7.5-12.2 5.5-2.1 11.5-4.4 16.9-5.8 4.1 3.4 10.1 5.5 15 5.5 3.5 0 6.3-2.9 6.3-6.3 0-3.5-2.9-6.3-6.3-6.3-4 0-9.6 1.4-13.9 2.9-3.5-3.4-6.7-7.5-9.2-11.9C50.6 37 52.6 32 52.6 28c-.2-3.5-2.9-6.2-6.4-6.2zm0 3.6c1.4 0 2.4 1.1 2.4 2.4 0 1.8-1.1 5.3-2.1 9-1.5-3.7-2.9-7.2-2.9-9 .1-1.2 1.2-2.4 2.6-2.4zm1.1 21.5c1.8 3.1 4.1 5.8 6.6 8.2-3.7 1.1-7.3 2.3-11 3.7 1.8-3.8 3.1-7.9 4.4-11.9zM72 55c1.4 0 2.4 1.1 2.4 2.4 0 1.4-1.1 2.4-2.4 2.4-2.9 0-6.9-1.2-10.1-3.1C65.6 56 69.7 55 72 55zM34.6 66.2c-1.8 3.2-3.5 6.1-4.7 7.6-.5.5-.9.6-1.7.6-1.4 0-2.4-1.1-2.4-2.4 0-.6.3-1.4.6-1.7 1.3-1.2 4.5-2.6 8.2-4.1z"
            ></path>`;
        case "xlsx":
          return `<path d="M62.1 30.9h14.1v9.4H62.1zm0 14.5h14.1v9.4H62.1zm0 14.3h14.1v9.4H62.1zm-19.2 0H57v9.4H42.9zm-19 0H38v9.4H23.9zm19.2-14.2h14.1v9.4H43.1zm-19.2 0H38v9.4H23.9zm19.2-14.6h14.1v9.4H43.1zm-19.2 0H38v9.4H23.9z"></path>`;
        case "docx":
          return `<path d="M29.6 53l-5.9-18.2c-.2-.7-.4-1-.5-1-.1-.1-.2-.1-.4-.2l-2.1-.6-.1-2.6h11l.2 2.6-2.1.6v.2c0 .2.1.7.4 1.2l2.2 7 3.9-11.7 3-.1 3.6 11.7 2.5-7c.1-.5.4-1 .4-1.2v-.1l-1.8-.5-.1-2.7h8.6l.2 2.6-2.3.7c-.1 0-.2.1-.4.1 0 .1-.2.2-.4.9L43 52.8l-3.1.2-3.3-11.2-3.9 11-3.1.2z"></path><path d="M57.8 30.2h21.7v3.4H57.8zm0 9.2h21.7v3.4H57.8zm0 9.4h21.7v3.4H57.8zm-36.2 9.6h57.9v3.4H21.6zm0 9.4h57.9v3.4H21.6z"></path>`;
      }
    },
  },
  computed: {
    BASE_URL() {
      return process.env.VUE_APP_BASE_BACKEND_URL;
    },
  },
};
</script>

<style scoped lang="scss">
.file {
  color: $text-main;
  display: flex;
  height: 50px;
  width: 100%;
  background-color: $background-gray;
  padding: 0;
  .icon {
    height: 50px;
    width: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: red;
    svg {
      fill: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      object-fit: cover;
      object-position: center;
      width: 50px;
    }
  }
  .information {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;

    .left-side {
      .file-name {
        font-size: 16px;
      }
      .extension {
        text-transform: uppercase;
        font-weight: normal;
      }
    }
    .noedit {
      opacity: 0;

      transform: translateY(-10px);

      transition: 400ms all ease-in-out;
    }
    .right-side {
      pointer-events: none;
      color: $text-main;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
    }
  }
}

.file:hover .right-side {
  opacity: 1;
  transform: translateY(0);
  pointer-events: visible;
}

.pdf-color {
  background-color: #c11e07;
}
.xlsx-color {
  background-color: #30723f;
}
.docx-color {
  background-color: #2372ba;
}
.img-color {
  background-color: $background-gray;
}
</style>
